import { type FC, useEffect } from 'react';
import styles from './Cookies.module.scss';
import Typography from '#components/shared/ui/Typography';
import type { WithTranslations } from '#i18n/index.ts';
import useLocalStorage from '#hooks/useLocalStorage.ts';
import Button from '#components/shared/ui/Button';
import flows from '#utils/flows.ts';

const Cookies: FC<WithTranslations> = ({ t }) => {
  const [cookiesAccepted, setCookiesAccepted] = useLocalStorage(
    'cookies_agree',
    flows.isCommercialLink() || localStorage.getItem('cookies_agree') || false
  );

  useEffect(() => {
    document.body.style.overflow = cookiesAccepted ? 'unset' : 'hidden';
  }, [cookiesAccepted]);

  const acceptCookiesHandler = (): void => {
    setCookiesAccepted(true);
  };

  return !cookiesAccepted ? (
    <>
      <div className={styles.backdrop}></div>
      <div className={styles.popover}>
        <Typography component="span" variant="h6" color="branded">
          {t.dialogs.cookies.title}
        </Typography>
        <Typography component="span" variant="capture" color="secondary">
          {t.dialogs.cookies.text}
        </Typography>
        <Button size="lg" variant="filled" className={styles.button} onClick={acceptCookiesHandler}>
          {t.dialogs.cookies.acceptAll}
        </Button>
        <Button
          size="lg"
          variant="outlined"
          className={styles.button}
          onClick={acceptCookiesHandler}
        >
          {t.dialogs.cookies.acceptEssential}
        </Button>
      </div>
    </>
  ) : (
    ''
  );
};

export default Cookies;
